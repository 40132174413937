export const path = {
  index: {
    url: '/',
    title: 'Табата таймер для интервальных тренировок и упражнений – Tabata timer',
    description:
      'Пожалуй лучший способ сжигания жира по японскому протоколу. Получите максимум пользы и эффективности тренировок tabata с Табата-таймер.ру',
  },
  tabata_list: {
    url: '/tabata',
    title: 'Тренировка по протоколу Табата - Tabata timer',
    description: 'Активная тренировочная сессия с таймером',
  },
  tabata_start: {
    url: '/tabata/:id',
    title: 'Тренировка по протоколу Табата - Tabata timer',
    description: 'Активная тренировочная сессия с таймером',
  },
  tabata_create: {
    url: '/tabata/create',
    title: 'Создать таймер Табата по своим настройкам - Tabata timer',
    description:
      'Создание своего уникального таймера для табата тренировок по личным предпочтениям или согласно актуальным задачам тренировочного процесса',
  },
  user: {
    url: '/user',
    title: 'Настройки пользователя - Tabata timer',
    description: 'Сохраненные настройки пользователя таймеров табата',
  },
  help: {
    url: '/help',
    title: 'Помощь по работе с таймером -Tabata timer',
    description: 'Полезная информация по натсройке тамера для своих стренировок',
  },
}

export const sounds = {
  beep: './mp3/beep.mp3',
  horn: './mp3/horn.mp3',
}
