function Help() {
  return (
    <div className='container'>
      <div className='row'>
        <div className='col-12'>
          <p>
            Для начала необходимо настроить параметры таймера тренировки. После нажатия кнопки "старт" запустится
            обратный отсчет (3 секунды) со звуковым сигналом (2 коротких, 1 длинный) для подготовки к первому раунду. В
            конце (3 секунды) каждого раунда или перерыва на отдых также прозвучит звуковой сигнал (2 коротких, 1
            длинный). Кнопка "Стоп" служит для остановки таймера.
          </p>
          <p>Хороших тренировок.</p>
        </div>
      </div>
    </div>
  )
}

export default Help
