function NotFound() {
  return (
    <div className='container-fluid h-100 d-flex align-items-center justify-content-center'>
      <div className='row'>
        <div className='col-12 text-center'>
          <h2>404</h2>
          <p>Страница не найдена</p>
        </div>
      </div>
    </div>
  )
}

export default NotFound
